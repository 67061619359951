<template>
    <v-container fluid>
        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
            <v-card flat>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <v-subheader class="headline">{{ $t('tasks') }}</v-subheader>
                        </v-col>
                        <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="4" class="text-right">
                            <v-btn :to="{name: 'task.create'}" class="white--text" color="indigo">
                                {{$t('create') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="false">
                        <v-col class="py-0" cols="12" sm="9">
                            <ValidationProvider ref="title" rules="min:1|max:255"
                                                v-slot="{ errors, valid }">
                                <v-text-field v-model="title" type="text" :error-messages="errors"
                                              :disabled="loading" prepend-icon="mdi-file-document-outline"
                                              :label="$t('task_name')" color="primary"
                                              clearable></v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="3">
                            <ValidationProvider ref="number" rules="min:1|max:255"
                                                v-slot="{ errors, valid }">
                                <v-text-field v-model="number" type="text" :error-messages="errors"
                                              :disabled="loading" prepend-icon="mdi-file-document-outline"
                                              :label="$t('number_task')" color="primary"
                                              clearable></v-text-field>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="px-4 py-4">
                    <v-row>
                        <v-col cols="12" sm="3" md="3">
                            <v-btn icon>
                                <v-icon @click="$router.push({name: 'task'})">
                                    mdi-view-headline
                                </v-icon>
                            </v-btn>
                            <v-btn icon @click="$router.push({name: 'task.kanban'})">
                                <v-icon>
                                    mdi-format-columns
                                </v-icon>
                            </v-btn>
                            <v-btn @click="setSubordinates()" :loading="loading">
                                 {{ subordinates === false ? $t('subordinates') : $t('my_tasks')}}
                            </v-btn>
                        </v-col>
                        <v-col v-if="false" cols="12" sm="9" md="9"
                               class="d-flex justify-center justify-sm-end align-self-center">
                            <v-btn @click="searchTask" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                   color="primary">{{ $t('search') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </ValidationObserver>
        <div class="kanban">
            <div
                 v-for="(item, index) in task_statusItems"
                   :key="item.slug + '_' + index">
                <div>{{item.name}} - {{item.task_total}}</div>
                <v-divider class="my-1 mb-5 my_hr" :style="'border-color:'+item.color"></v-divider>

                <draggable :disabled="!item.disabled" class="list-group"
                           :list="item.tasks"
                           group="people"
                           @change="changeDrag($event,item.action)"
                           @start="startDrag(item,index,item.tasks)"
                           @end="endDrag"
                >

                    <div
                        class="list-group-item"
                        v-for="(element, index) in item.tasks"
                        :key="element.number + '_' + index"
                    >
                        <div class="kanban_task" :style="'border-left: 3px solid' +  item.color">
                            <div class="body-2 kanban_link" @click="showTask(element)">{{ element.number }}. {{ element.title }}</div>
                            <v-col cols="12" class="text-right">
                                <v-avatar size="36" class="border_blue my-0 py-0">
                                    <img v-if="element.admin.photo !== '/img/avatar.png'" :src="element.admin.photo" :alt="element.admin.name">
                                    <v-icon v-else color="primary" size="22">
                                        ${{'settingsIcon'}}
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                        </div>
                    </div>

                </draggable>

            </div>
        </div>


        <v-dialog
            v-model="task_bet_fields_dialog"
            scrollable
            persistent
            max-width="1200px"
            transition="dialog-bottom-transition"
            style="z-index:600 !important;"
        >
            <ValidationObserver ref="observer2" v-slot="{ invalid, validated, passes, validate }">

                <v-card z-index="600">
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('fill_in_fields') }}</span>
                        </div>
                        <div>
                            <v-icon @click="taskBetFildsClose();">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text  class="mt-5">
                        <v-row>
                            <v-col cols="12" class="py-0">
                                <ValidationProvider ref="margin" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <div class="input_label">{{$t('margin')}}</div>
                                    <v-text-field v-model="margin"
                                                  :error="!valid"
                                                  hide-details
                                                  :label="$t('margin')"
                                                  :error-messages="errors"
                                                  :disabled="loading"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                  clearable
                                    ></v-text-field>


                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <ValidationProvider ref="delivery_time" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <div class="input_label">{{$t('delivery_time_in_days')}}</div>
                                    <v-text-field v-model="delivery_time"
                                                  :error="!valid"
                                                  :label="$t('delivery_time_in_days')"
                                                  hide-details
                                                  :error-messages="errors"
                                                  :disabled="loading"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense

                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                  clearable
                                    ></v-text-field>


                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :headers="headerBets" :items="taskBetItems" :loading="loading" :locale="lang"
                                              :loading-text="$t('loading_please_wait')"
                                              hide-default-footer
                                              :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                              class="elevation-0"
                                              :item-class="rowBet"
                                              item-key="task_bet_uuid"
                                              :single-select="true"
                                              @click:row="selectRow"
                                >
                                    <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                        <thead>
                                        <tr class="rowHeadClass TableTaskBet">
                                            <th v-for="h in headers" :key="h.value" class="table_header">
                                                <span>{{h.text}}</span>
                                            </th>
                                        </tr>
                                        </thead>
                                    </template>

                                    <template v-slot:item.supplier.name="{ item, index }">
                                        <v-hover v-slot="{ hover }" open-delay="100">
                                            <div :class="(task_bet_item === item || item.accepted === true) ? 'text_bold' : ''">
                                                <span class="cursor-pointer">
                                                    {{ item.supplier.name ? item.supplier.name : $t('enter_text') }}
                                                </span>
                                            </div>
                                        </v-hover>
                                    </template>

                                    <template v-slot:item.type_transport.name="{ item, index }">
                                        <div :class="(task_bet_item === item || item.accepted === true) ? 'text_bold' : ''">
                                            {{item.type_transport.name === '--' ? $t('select_value') : item.type_transport.name}}
                                        </div>
                                    </template>

                                    <template v-slot:item.type_container.name="{ item, index }">
                                        <div :class="(task_bet_item === item || item.accepted === true) ? 'text_bold' : ''">
                                            {{item.type_container.name === '--' ? $t('select_value') : item.type_container.name}}
                                        </div>
                                    </template>

                                    <template v-slot:item.date_dispatch="{ item, index }">
                                        <div :class="(task_bet_item === item || item.accepted === true) ? 'text_bold' : ''">
                                            {{!item.date_dispatch ? $t('select_value') : $moment(item.date_dispatch).format('DD.MM.YYYY') }}
                                        </div>
                                    </template>


                                    <template v-slot:item.price="{ item, index }" >

                                        <div :class="(task_bet_item === item || item.accepted === true) ? 'text_bold' : ''">
                                            {{ item.price ? item.price : item.price = 0 }} $
                                        </div>

                                    </template>


                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="editStatus()"  :disabled="invalid || loading" :loading="loading">
                            {{ $t('done')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>

        </v-dialog>

    </v-container>
</template>
<script>

    import {mapGetters} from "vuex"
    import draggable from "vuedraggable";
    import {ValidationObserver, ValidationProvider} from "vee-validate";

    export default {
        name: "TaskKanban",
        order: 1,
        components: {
            draggable,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                loading: false,
                drag: false,
                task_statusItems: [],
                task_status: null,
                page: 1,
                get_scroll: 1,
                number: null,
                title: null,
                perPage: 15,
                startTask: [],
                task_bet_fields_dialog: false,
                delivery_time: null,
                margin: null,
                taskBetItems:[],
                task_bet_item:{},
                status_delete:null,
                uuid_delete:null,
                status_cancel:null,
                task_type_id:null,
                task_drag:{},
                headerBets: [
                    {
                        text: this.$t('supplier'),
                        align: "left",
                        sortable: false,
                        value: "supplier.name"
                    },
                    {
                        text: this.$t('type_transport'),
                        align: "left",
                        sortable: false,
                        value: "type_transport.name"
                    },
                    {
                        text: this.$t('type_container'),
                        align: "left",
                        sortable: false,
                        value: "type_container.name"
                    },
                    {
                        text: this.$t('date_dispatch'),
                        align: "left",
                        sortable: false,
                        value: "date_dispatch"
                    },
                    {
                        text: this.$t('price'),
                        align: "left",
                        sortable: false,
                        value: "price"
                    },
                ],
                subordinates: false,
            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        async mounted() {
            await this.getTaskStatuses()
            await this.getTasks()
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                if (bottomOfWindow && this.get_scroll === 1) {
                    this.page++;
                    this.getTasks();
                }
            };
        },
        methods: {
            startDrag(item, index, tasks){
               this.startTask = Object.assign([], tasks);
               const indexToComplete = this.task_statusItems.findIndex(obj => obj.action ===  'complete');
               this.task_statusItems[indexToComplete].disabled =  false;
               if(item.action !== ''){
                   for (let i=0; i<index; i++){
                       this.task_statusItems[i].disabled = false;
                   }
                   if(item.action === 'pause'){
                       this.task_statusItems[1].disabled = true;
                   }
                   else if(item.action === 'approved'){
                       this.task_statusItems[indexToComplete].disabled = true;
                   }
                   else if(item.action === 'complete'){
                       for (const value of this.task_statusItems) {
                           value.disabled = true
                       }
                   }
               }
            },
            async  endDrag(evt){

                if(this.status_delete === 'approved' && this.task_type_id === 3){
                    await this.getTaskBet(this.uuid_delete);
                    if(this.taskBetItems.length === 0){
                        this.cancelDrag();
                        this.$toastr.error(this.$t('no_bids_you_need_to_add_bid'));
                        this.taskStatusDisabledTrue();
                        return;
                    }
                    this.task_bet_fields_dialog = true
                }
                else if(this.status_delete === 'done' && this.task_type_id === 3){
                    await this.getTaskBet(this.uuid_delete);
                    if(this.taskBetItems.length === 0){
                        this.cancelDrag();
                        this.$toastr.error(this.$t('no_bids_you_need_to_add_bid'))
                        this.taskStatusDisabledTrue();
                        return;
                    }
                    await this.editStatus();
                }

                else if(this.status_delete != null && this.task_type_id != null) {
                    await this.editStatus();
                }

                for (const value of this.task_statusItems) {
                    value.disabled = true
                }
            },
            async changeDrag(evt, status) {
                if(evt && evt.added && evt.added.element && evt.added.element.uuid){
                    this.status_delete = status;
                    this.uuid_delete = evt.added.element.uuid;
                    this.task_type_id = evt.added.element.task_type_id;
                }
                if(evt && evt.removed && evt.removed.element)
                {
                    this.status_cancel = status;
                }

            },
            async getTaskBet(task_uuid) {

                this.loading = true
                let params = {}
                // params.filter = 'search'
                params.task_uuid = task_uuid
                await this.$http
                    .get("admin/task_bet", {
                        params: params,
                    })
                    .then(res => {
                        this.taskBetItems = res.body.data
                    })
                    .catch(err => {
                        this.taskBetItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })

            },
            taskStatusDisabledTrue(){
                for (const value of this.task_statusItems) {
                    value.disabled = true
                }
            },
            searchTask(){
                this.page = 1;
                this.getTasks();

            },
            async getTaskStatuses() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/task_status", {
                        params: params,
                    })
                    .then(res => {
                        this.task_statusItems = res.body.data
                    })
                    .catch(err => {
                        this.task_statusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getTasks() {
                this.get_scroll = 0;
                for (const value of this.task_statusItems) {
                    this.loading = true;
                    let params = {};
                    params.sortBy = 'id';
                    params.sortDir = 'desc';
                    params.perPage = this.perPage;
                    params.page = this.page
                    params.active = 1

                    if (this.number) {
                        params.number = this.number;
                    }
                    if (this.title) {
                        params.title = this.title;
                    }
                    let add_filter = '';
                    if(this.subordinates === true){
                       add_filter = 'fltr[all_tasks][eq]=1&';
                    }

                    await this.$http
                        .get("admin/task?"+add_filter+"fltr[task_status_id][eq]="+value.id, {
                       // .get("admin/task?fltr[all_tasks][eq]=1&fltr[task_status_id][eq]="+value.id, {
                            params: params,
                        })
                        .then(res => {
                            if(this.page === 1){
                                this.get_scroll = 1;
                                value.tasks = res.body.data
                                value.task_total = res.body.meta.total;
                            }
                            else {
                                for (const item of res.body.data) {
                                    this.get_scroll = 1;
                                    value.tasks.push(item);
                                }
                            }
                        })
                        .catch(err => {
                            this.tasks = []
                            this.totalTasks = 0
                            this.$toastr.error(this.$t('failed_to_get_list_tasks'))
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            async editStatus() {
                var _this = this;
                this.progress = 0
                var formData = new FormData()
                let err = 0;
                let task_uuid = this.uuid_delete;
                let status =this.status_delete;

                if(status === 'approved' && this.task_type_id === 3){
                    if(typeof this.margin === 'undefined' ||  this.margin === null || this.margin === ''){
                        err = 1
                    }

                    if(typeof this.delivery_time === 'undefined' ||  this.delivery_time === null || this.delivery_time === ''){
                        err = 1
                    }

                    if(err === 1){
                        this.$toastr.error(this.$t('fill_in_field'))
                        return;
                    }

                    if(this.task_bet_item === null || ( typeof this.task_bet_item.id === 'undefined' ||  this.task_bet_item.id === null || this.task_bet_item.id === '')){
                        this.$toastr.error(this.$t('select_bet_that_passed'))
                        return;
                    }
                }


                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])

                }
                if(this.task_type_id){
                    formData.append('task_type',  this.task_type_id)
                }
                if(this.margin){
                    formData.append('margin',  this.margin)
                }
                if(this.delivery_time){
                    formData.append('delivery_time',  this.delivery_time)
                }

                if (this.field_status_task) {
                    if (this.field_status_task.id) {
                        formData.append('field_status_task', this.field_status_task.id)
                    } else {
                        formData.append('field_status_task', this.field_status_task)
                    }
                }

                if(this.task_bet_item && this.task_bet_item.id){
                    formData.append('task_bet_id',  this.task_bet_item.id)
                }

                formData.append('task_status', status)
                formData.append('id_array', 1000 )

                this.loading = true


                await this.$http
                    .put(`admin/task/status/${task_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.closeForm()
                        this.$toastr.success(this.$t('task_has_been_updated'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('task_has_not_been_updated'))
                        this.cancelDrag();
                        this.closeForm();
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }

                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },
            showTask(item) {
                this.$router.push({
                    name: 'task.show',
                    params: {
                        id: item.uuid
                    }
                })
            },
            taskBetFildsClose(){
                this.delivery_time = null;
                this.margin = null;
                this.task_bet_item = {}
                this.task_bet_fields_dialog = false;
                this.cancelDrag();
            },
            cancelDrag(){
                const item = this.task_statusItems.find(obj => obj.action ===  this.status_delete);
                const indexToRemove = item.tasks.findIndex(item => item.uuid === this.uuid_delete);
                if (indexToRemove !== -1) {
                    item.tasks.splice(indexToRemove, 1);
                }
                const item2 = this.task_statusItems.find(obj => obj.action ===  this.status_cancel);
                item2.tasks = this.startTask;
                this.startTask = []
                this.status_delete = null;
                this.uuid_delete = null;
                this.status_cancel = null;
                this.task_type_id = null;
            },
            closeForm(){
                this.task_bet_fields_dialog = false;
                this.delivery_time = null;
                this.margin = null;
                this.task_bet_item = {}
                this.startTask = []
                this.status_delete = null;
                this.uuid_delete = null;
                this.status_cancel = null;
                this.task_type_id = null;
            },
            rowBet(){
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass TableTaskBet' : 'rowMobiClass';
            },
            selectRow(item) {
                if(this.task_bet_item === item){
                    this.task_bet_item = {}
                }
                else{
                    this.task_bet_item = item
                }
            },
            setSubordinates(){
                for (const value of this.task_statusItems) {
                    value.tasks = []
                }
                this.page = 1;
                if(this.subordinates === false){
                    this.subordinates = true;
                }
                else{
                    this.subordinates = false;
                }
                this.getTasks();
            }
        }
    }
</script>

<style>
.kanban{
    flex-grow: 1;display:flex;margin-bottom: 1rem; overflow: auto
}
.kanban > div{
    flex-shrink: 0;width: 320px;padding: 10px 10px 0;overflow-y: auto;margin-right: 20px;
}
.kanban_task{
    -webkit-box-shadow: 0 0 2px 0 red;
    box-shadow: 0 0 2px 0 #b3b3b3,0 2px 2px 0 #b3b3b3;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: 12px 16px;
    position: relative;
    -webkit-transition: -webkit-box-shadow .3s;
    transition: -webkit-box-shadow .3s;
    -o-transition: box-shadow .3s;
    cursor: all-scroll;
    min-height: 40px;
    border-radius: 3px;

}
.kanban_link{
    cursor: pointer;
}
.kanban_link:hover{
    color: #64B5F6;
}
.sortable-ghost{
    min-height: 40px;
    opacity: 0.5;
    z-index: 100;
    margin-bottom: 15px;
    border: dashed 2px;
    -webkit-box-shadow: none;
    box-shadow:none;
    border-radius:0;
    -webkit-box-sizing: unset;
    box-sizing: unset;
    padding:0;
}
.sortable-ghost div{
    -webkit-box-shadow: none;
    box-shadow:none;
    min-height: 40px;
    border: 0 !important;

}
.sortable-ghost div div{
    visibility: hidden;
}
</style>
